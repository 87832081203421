import {BuroBilgiEntity} from "@/entity/BuroBilgiEntity";
import {PersonelListResponseProvider} from "@/services/PersonelService";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";
import {AdresListResponseProvider} from "@/services/AdresService";
import {IletisimBilgisiListResponseProvider} from "@/services/IletisimBilgisiService";
import { KisiListResponseProvider } from './KisiService';
import { KamuOzel } from '@/enum/KamuOzel';
import { KisiKurumTuru, KisiKurumTuruLabel } from '@/enum/KisiKurumTuru';
import { AdresEntity } from '@/entity/AdresEntity';
import { IletisimBilgisiEntity } from '@/entity/IletisimBilgisiEntity';
import { PersonelEntity } from '@/entity/PersonelEntity';
import { HesapNumarasiEntity } from '@/entity/HesapNumarasiEntity';
import { KisiEntity } from '@/entity/KisiEntity';

export class BuroListResponse {
    data!: BuroBilgiEntity[]
}

export function BuroListResponseProvider(depth:number=4): BuroListResponse {
    let adreslerResponse = Array<AdresEntity>();
    let iletisimBilgileriResponse = Array<IletisimBilgisiEntity>();
    let personellerResponse = Array<PersonelEntity>();
    let hesaplarResponse =  Array<HesapNumarasiEntity>();
    let kisilerResponse = Array<KisiEntity>();
    if(--depth > 0)
    {
        adreslerResponse = AdresListResponseProvider().data;
        iletisimBilgileriResponse = IletisimBilgisiListResponseProvider(depth).data;
        personellerResponse = PersonelListResponseProvider(depth).data;
        hesaplarResponse =  BankaHesapListResponseProvider(depth).data;
        kisilerResponse = KisiListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                ad: "Zirve İzmir Bürosu",
                vergiDairesi: "İzmir",
                vergiNo: 1298073587,
                personeller: personellerResponse,
                hesaplar: hesaplarResponse,
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                ortaklar: kisilerResponse,
                mersisNo: "55111315121",
                ticaretSicilNo: null,
                kisiKurumTuru: KisiKurumTuru.sirket,
                kamuOzel: KamuOzel.ozel 
            },
            {
                id: 2,
                ad: "İzmir Ofisi",
                vergiDairesi: "İstanbul",
                vergiNo: 1859361912,
                personeller: [],
                hesaplar: [],
                adresler: adreslerResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                ortaklar: kisilerResponse,
                mersisNo: "55111314435787",
                ticaretSicilNo: null,
                kisiKurumTuru: KisiKurumTuru.sirket,
                kamuOzel: KamuOzel.ozel 
            },
        ]
    }
}


export interface BuroDetayResponse {
    data: BuroBilgiEntity;
}

export interface BuroUpdateRequest {

}

export interface BuroCreateRequest {

}




