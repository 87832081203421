




























import {Component, Vue} from "vue-property-decorator";
import {PersonelListResponseProvider} from "@/services/PersonelService";

import HesapNoList from "@/components/comps/lists/HesapNoList.vue";
import IletisimList from "@/components/comps/lists/IletisimList.vue";
import AdresList from "@/components/comps/lists/AdresList.vue";
import KimlikBilgileriList from "@/components/comps/lists/KimlikBilgileriList.vue";
import {AvukatPersonelEntity} from "@/entity/AvukatPersonelEntity";
import {DigerPersonelEntity} from "@/entity/DigerPersonelEntity";
import {PersonelTuru} from "@/enum/PersonelTuru";
import PersonelBilgiList from "@/components/buro/personel/PersonelBilgiList.vue";

@Component({
    components: {PersonelBilgiList, KimlikBilgileriList, AdresList, IletisimList, HesapNoList}
})
export default class PersonelDetay extends Vue {

    item: AvukatPersonelEntity | DigerPersonelEntity | null = null;


    mounted() {

        window.setTimeout(() => {
            let index: number = parseInt(this.$route.params.id) - 1;
            this.item = PersonelListResponseProvider(5).data[index];
            console.log("Personeller: ", this.item);
        }, 200);


    }

    get isPersonelAvukat() {
        if (!this.item) {
            return false;
        }
        return this.item.personelTuru == PersonelTuru.avukat || this.item.personelTuru == PersonelTuru.stajyerAvukat;
    }

}
