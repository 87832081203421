import { PersonelIseGirisCikisEntity } from '@/entity/PersonelIseGirisCikisEntity';
import { PersonelListResponseProvider } from './PersonelService';
import { PersonelEntity } from '@/entity/PersonelEntity';

export interface PersoneIseGirisCikislListResponse {
    data: Array<PersonelIseGirisCikisEntity>;
}

export function PersoneIseGirisCikislListResponseProvider(depth:number=3): PersoneIseGirisCikislListResponse {
    let personellerResponse = Array<PersonelEntity>();
    if(--depth > 0)
    {
        personellerResponse = PersonelListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                personel: personellerResponse[0],
                aktif: true,
                cikisTarihi: new Date("2019-02-19"),
                girisTarihi: new Date("2017-02-19")
            },
            {
                id: 2,
                personel: personellerResponse[1],
                aktif: true,
                cikisTarihi: new Date("2019-02-19"),
                girisTarihi: new Date("2017-02-19")
            },
            {
                id: 3,
                personel: personellerResponse[2],
                aktif: true,
                cikisTarihi: null,
                girisTarihi: new Date("2017-02-19")
            }
        ]
    }
}

