import {PersonelTuru} from "@/enum/PersonelTuru";
import {Cinsiyet} from "@/enum/Cinsiyet";
import {AdresListResponseProvider} from "@/services/AdresService";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";
import {IletisimBilgisiListResponseProvider} from "@/services/IletisimBilgisiService";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {AvukatPersonelEntity} from "@/entity/AvukatPersonelEntity";
import {DigerPersonelEntity} from "@/entity/DigerPersonelEntity";
import {VekilTipi} from "@/enum/VekilTipi";
import {IlListResponseProvider} from "@/services/IlService";
import { BuroListResponseProvider } from './BuroService';
import { PersoneIseGirisCikislListResponseProvider } from './PersonelIseGiriCikisService';
import { PersonelIseGirisCikisEntity } from '@/entity/PersonelIseGirisCikisEntity';
import { BuroBilgiEntity } from '@/entity/BuroBilgiEntity';
import { AdresEntity } from '@/entity/AdresEntity';
import { HesapNumarasiEntity } from '@/entity/HesapNumarasiEntity';
import { IletisimBilgisiEntity } from '@/entity/IletisimBilgisiEntity';
import { IlEntity } from '@/entity/IlEntity';
import {KimlikBelgesiEntity} from "@/entity/KimlikBelgesiEntity";
import {KimlikBelgeleriResponseProvider} from "@/services/KimlikBelgeleriService";


export interface PersonelListResponse {
    data: Array<AvukatPersonelEntity | DigerPersonelEntity>;
}

export function PersonelListResponseProvider(depth:number=3): PersonelListResponse {
    let iseGirisCikislarResponse = Array<PersonelIseGirisCikisEntity>();
    let burolarResponse = Array<BuroBilgiEntity>();
    let adreslerResponse = Array<AdresEntity>();
    let hesaplarResponse = Array<HesapNumarasiEntity>();
    let iletisimBilgileriResponse = Array<IletisimBilgisiEntity>();
    let illerResponse = Array<IlEntity>();
    let kimlikResponse = Array<KimlikBelgesiEntity>();
    if(--depth > 0)
    {
        iseGirisCikislarResponse = PersoneIseGirisCikislListResponseProvider(depth).data;
        burolarResponse = BuroListResponseProvider(depth).data;
        adreslerResponse = AdresListResponseProvider(depth).data;
        hesaplarResponse = BankaHesapListResponseProvider(depth).data;
        iletisimBilgileriResponse = IletisimBilgisiListResponseProvider(depth).data;
        illerResponse = IlListResponseProvider(depth).data;
        kimlikResponse = KimlikBelgeleriResponseProvider(depth).data;
    }
    return {
        data: [
            <AvukatPersonelEntity>{
                id: 1,
                buro: burolarResponse[0],
                personelTuru: PersonelTuru.avukat,
                adresler: adreslerResponse,
                hesaplar: hesaplarResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                ad: 'Ceylan',
                soyad: 'Küçükyılmaz',
                tc: '33019850213',
                cinsiyet: Cinsiyet.kadin,
                sgkNo: '0235498762',
                girisCikis: [
                    iseGirisCikislarResponse[0]
                ],
                maas: [],
                dogumTarihi: new Date("2020/05/12"),
                dogumYeri: illerResponse[0],
                harcMuaf: null,
                kisiKurumTuru: KisiKurumTuru.sahis,
                baroSicilNo: "1231234",
                tbbSicilNo: "2323345",
                vekilTipi: VekilTipi.sahis,
                ortakOlduguKurumlar: [],
                kimlikBelgeleri:kimlikResponse,
            },
            <AvukatPersonelEntity>{
                id: 2,
                buro: burolarResponse[0],
                personelTuru: PersonelTuru.avukat,
                adresler: adreslerResponse,
                hesaplar: hesaplarResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                ad: 'Furkan',
                soyad: 'Şahin',
                tc: '44120961324',
                cinsiyet: Cinsiyet.erkek,
                sgkNo: '1235498732',
                girisCikis: [
                    iseGirisCikislarResponse[1]
                ],
                maas: [],
                dogumTarihi: new Date("2000/05/12"),
                dogumYeri: illerResponse[0],
                kimlikBelgeleri:kimlikResponse,
                harcMuaf: null,
                kisiKurumTuru: KisiKurumTuru.sahis,
                baroSicilNo: "1231234",
                tbbSicilNo: "2323345",
                vekilTipi: VekilTipi.sahis,
                ortakOlduguKurumlar: []
            },
            <DigerPersonelEntity>{
                id: 3,
                buro: burolarResponse[0],
                personelTuru: PersonelTuru.sysAdm,
                ad: 'Sebo',
                dogumYeri: illerResponse[1],
                adresler: adreslerResponse,
                hesaplar: hesaplarResponse,
                iletisimBilgileri: iletisimBilgileriResponse,
                soyad: 'Çalışkan',
                tc: '12765319413',
                cinsiyet: Cinsiyet.erkek,
                sgkNo: '4902315812',
                kisiKurumTuru: KisiKurumTuru.sahis,
                girisCikis: [
                    iseGirisCikislarResponse[2]
                ],
                maas: [],
                dogumTarihi: new Date("2020/05/12"),
                dogumyeri: "Ankara Kızılay",
                kimlikBelgeleri:kimlikResponse,
                harcMuaf: null,
                ortakOlduguKurumlar: []
            },

        ]
    }
}

